<template>
  <div class="category">
    <div class="category__view">
      <h1 class="category__title">Crear nueva categoria</h1>
      <span class="category__close" @click="closeModal"><iconic name="close"></iconic></span>
    </div>
    <div class="category__line"></div>
    <label for="" class="category__label">Nombre de la categoria</label>
    <input type="text" class="category__data" id="category__data" v-model="nameCategory" @input="cleanWarning" />
    <span class="category__error" v-if="error">Debes llenar este campo</span>
    <div class="category__btns">
      <button class="category__btn" @click="closeModal">Cancelar</button>
      <button class="category__btn" @click="createNewCategory" id="btnClose">Crear</button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      nameCategory: "",
      error: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit("clicked", this.data);
    },
    cleanWarning(input) {
      if (input.srcElement.value !== "") {
        document.getElementById("category__data").style.border = "none";
        this.error = false;
      }
    },
    async createNewCategory() {
      if (this.nameCategory === "") {
        document.getElementById("category__data").style.border = "1px solid #bd0909";
        this.error = true;
        return;
      }
      await this.$store.dispatch("newsCategories/createNewCategory", this.nameCategory);
      this.closeModal();
    },
  },
};
</script>
<style lang="scss">
.category {
  width: 350px;
  display: flex;
  flex-direction: column;
  border: 1px solid #b0adad;
  padding: 10px 0 20px 0;
  border-radius: 12px;
  z-index: 20;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  &__view {
    display: flex;
    justify-content: space-between;
  }
  &__close {
    color: $primary_color;
    padding: 18px 15px 0 0;
    cursor: pointer;
  }
  &__title {
    margin: 10px 0 0 20px;
  }
  &__line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid hsla(0, 0%, 67.1%, 0.602);
    margin: 10px 0;
  }
  &__label {
    margin: 0 20px 5px;
  }
  &__data {
    margin: 0 20px 20px;
    border-radius: 6px;
    padding: 6px 8px;
    outline: none;
    border: 1px solid #d8d8d8;
  }
  &__error {
    margin: -15px 0 15px 20px;
    color: $primary_color;
  }
  &__btns {
    :nth-child(1) {
      font-weight: 700;
      background-color: #fff;
      color: $primary_color;
      border: 1px solid $primary_color;
      &:hover {
        background-color: $primary_color;
        color: #fff;
      }
    }
    margin: 0 auto;
  }
  &__btn {
    font-weight: 700;
    font-family: Montserrat;
    outline: none;
    width: 100px;
    border: none;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 2px;
    background-color: $primary_color;
    color: #fff;
    border-radius: 5px;
    margin: 0 5px 10px;
    &:hover {
      opacity: $opacity-button;
    }
  }
}
</style>
