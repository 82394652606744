<script>
  export default {
    props: ["title", "conditions", "iconName"],
    computed: {
      splitConditions() {
        return this.conditions.map((condition) => {
          const [beforeColon, afterColon] = condition.split(":");
          return { beforeColon, afterColon: afterColon ? afterColon.trim() : "" };
        });
      },
    },
  };
</script>

<template>
  <div class="RequirementCard">
    <div class="RequirementCard__iconContainer">
      <iconic :name="iconName" class="RequirementCard__icon" />
    </div>
    <div class="RequirementCard__content">
      <div class="RequirementCard__title">{{ title }}</div>
      <ul class="RequirementCard__conditionList">
        <li class="RequirementCard__conditionItem" v-for="(condition, index) in splitConditions" :key="index">
          {{ condition.beforeColon }}
          <span class="RequirementCard__conditionText" v-if="condition.afterColon">: {{ condition.afterColon }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
  .RequirementCard {
    @include Flex(row, flex-start, flex-start);
    width: 100%;
    margin-bottom: 10px;
    padding: $space-8;
    border-radius: $radius-12;
    background-color: rgba(189, 9, 9, 0.1);

    &__iconContainer {
      @include Flex();
    }

    &__icon {
      margin-top: $space-2;
      font-size: 20px;
    }
    &__title {
      font-family: $first_font;
      font-size: 16px;
      font-weight: 600;
    }

    &__content {
      @include Flex(column, center, flex-start);
      width: 100%;
      margin-left: $space-6;
    }

    &__conditionList {
      margin-left: $space-6;
    }

    &__conditionItem {
      margin: $space-0 $space-0 $space-2 $space-16;
      font-family: $first_font;
      list-style: disc;
    }
    &__conditionText {
      font-weight: bold;
    }
  }
</style>
